import React from "react";
import { Card, Row, Col, Typography } from "antd";
import { Fade } from "react-awesome-reveal";
import Player from "lottie-react";
import taxAnimation from "../public/taxAnim.json";
import personalAnimation from "../public/personalAnim.json";
import realEstateAnimation from "../public/realEstateAnim.json";
import basicAnimation from "../public/basicAnim.json";
import oldAnimation from "../public/oldAnim.json";
import savingsAnimation from "../public/savingsAnim.json";
import businessAnimation from "../public/businessAnim.json";

const { Title, Paragraph } = Typography;

const handleTaxClick = () => {
  window.location.href = "/tax";
};

const cardsData = [
  {
    title: "Basic Knowledge",
    description: "Explore employee and employer social contributions.",
    color: "#f44336",
    animation: basicAnimation,
  },
  {
    title: "Taxes",
    description: "Learn about Croatia's progressive income tax system.",
    color: "#4caf50",
    onClick: handleTaxClick,
    animation: taxAnimation,
  },
  {
    title: "Personal Budgeting",
    description: "Understand the various VAT rates applied in Croatia.",
    color: "#2196f3",
    animation: personalAnimation,
  },
  {
    title: "Saving and Investing",
    description: "Find out how corporate tax rates vary by revenue.",
    color: "#ff9800",
    animation: savingsAnimation,
  },
  {
    title: "Real Estate and Mortgages",
    description: "Explore employee and employer social contributions.",
    color: "#a9ac22",
    animation: realEstateAnimation,
  },
  {
    title: "Small Business Finance",
    description: "Explore employee and employer social contributions.",
    color: "#995390",
    animation: businessAnimation,
  },
  {
    title: "Pension",
    description: "Explore employee and employer social contributions.",
    color: "#5c1794",
    animation: oldAnimation,
  },
];

const DashboardScreen: React.FC = () => {
  return (
    <div style={{ padding: "40px", backgroundColor: "#f9f9f9", minHeight: "100vh" }}>
      <Row gutter={[16, 16]} justify="center">
        {cardsData.map((card, index) => (
          <Col
            key={index}
            xs={24}
            sm={12}
            md={index % 3 === 0 ? 12 : 8} // Vary card sizes
            lg={index % 3 === 0 ? 12 : 6}
            style={{ textAlign: "center" }}
          >
            <Title level={4} style={{ marginBottom: "10px", color: card.color }}>
              {card.title}
            </Title>
            <Fade direction="up" delay={index * 100} triggerOnce>
              <Card
                hoverable
                style={{
                  position: "relative",
                  overflow: "hidden",
                  borderRadius: "10px",
                  textAlign: "center",
                  height: index % 3 === 0 ? "300px" : "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  color: "#fff",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease-in-out",
                }}
                bodyStyle={{
                  padding: "20px",
                  zIndex: 2, // Ensure content is above the animation
                }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                onClick={card.onClick}
              >
                {/* Lottie Animation as Background */}
                <Player
                  autoplay
                  loop
                  animationData={card.animation}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1, // Behind the card content
                    pointerEvents: "none", // Allow clicks to pass through the animation
                  }}
                />

                <Paragraph style={{ color: "#fff" }}>{card.description}</Paragraph>
              </Card>
            </Fade>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default DashboardScreen;
