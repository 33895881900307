import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Modal, Button, Layout, Menu, Typography, Input, Row, Col, Form, message } from "antd";
import { Fade } from "react-awesome-reveal";
import { MenuOutlined } from "@ant-design/icons";
import Logo from "../public/logo.png";

const { Header, Content } = Layout;
const { Title } = Typography;

const HomeScreen: React.FC = () => {
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isForgotModalOpen, setIsForgotModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    if (token && storedUsername) {
      setIsLoggedIn(true);
      setUsername(storedUsername);
    }
  }, []);

  const handleModalClose = () => {
    setIsRegisterModalOpen(false);
    setIsLoginModalOpen(false);
    setIsForgotModalOpen(false);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header className="px-4 md:px-12">
        <Row align="middle" justify="space-between" className="w-full">
          <Col>
            <div className="flex items-center cursor-pointer" onClick={() => window.location.href = "/"}>
              <img src={Logo} alt="Logo" className="h-8 md:h-10 mr-2" />
              <Title level={3} style={{ color: "white" }} className="text-white m-0 hidden md:block">Budget Buddies</Title>
            </div>
          </Col>
          
          {/* Desktop Menu */}
          <Col className="hidden md:block">
            <Menu mode="horizontal" theme="dark" className="border-b-0">
              <Menu.Item 
                key="simulations" 
                onClick={() => window.location.href = "/dashboard"}
                className="text-lg px-6 hover:text-blue-400 transition-colors duration-300"
              >
                Simulations
              </Menu.Item>
              <Menu.Item 
                key="budgeting" 
                onClick={() => window.location.href = "/budgeting"}
                className="text-lg px-6 hover:text-blue-400 transition-colors duration-300"
              >
                Budgeting
              </Menu.Item>
              <Menu.Item 
                key="aboutus" 
                onClick={() => window.location.href = "/aboutus"}
                className="text-lg px-6 hover:text-blue-400 transition-colors duration-300"
              >
                About Us
              </Menu.Item>
              <Menu.Item 
                key="contact" 
                onClick={() => window.location.href = "/contact"}
                className="text-lg px-6 hover:text-blue-400 transition-colors duration-300"
              >
                Contact
              </Menu.Item>
            </Menu>
          </Col>
          
          {/* Desktop Auth Buttons */}
          <Col className="hidden md:block">
            {isLoggedIn ? (
              <div className="flex items-center">
                <span className="text-white mr-4">Welcome, {username}</span>
                <Button onClick={() => {
                  localStorage.removeItem('token');
                  localStorage.removeItem('username');
                  setIsLoggedIn(false);
                  setUsername("");
                }}>Logout</Button>
              </div>
            ) : (
              <>
                <Button className="mr-1" onClick={() => setIsLoginModalOpen(true)}>Login</Button>
                <Button type="primary" onClick={() => setIsRegisterModalOpen(true)}>Register</Button>
              </>
            )}
          </Col>

          {/* Mobile Menu Button */}
          <Col className="md:hidden">
            <Button 
              type="text" 
              icon={<MenuOutlined className="text-white text-xl" />}
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            />
          </Col>
        </Row>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden bg-[#001529] absolute left-0 right-0 top-[64px] z-50 border-t border-gray-700">
            <Menu mode="inline" theme="dark" className="py-2">
              <Menu.Item 
                key="simulations" 
                onClick={() => { window.location.href = "/dashboard"; setIsMobileMenuOpen(false); }}
                className="text-lg py-3 hover:text-blue-400 transition-colors duration-300"
              >
                Simulations
              </Menu.Item>
              <Menu.Item 
                key="budgeting" 
                onClick={() => { window.location.href = "/budgeting"; setIsMobileMenuOpen(false); }}
                className="text-lg py-3 hover:text-blue-400 transition-colors duration-300"
              >
                Budgeting
              </Menu.Item>
              <Menu.Item 
                key="aboutus" 
                onClick={() => { window.location.href = "/aboutus"; setIsMobileMenuOpen(false); }}
                className="text-lg py-3 hover:text-blue-400 transition-colors duration-300"
              >
                About Us
              </Menu.Item>
              <Menu.Item 
                key="contact" 
                onClick={() => { window.location.href = "/contact"; setIsMobileMenuOpen(false); }}
                className="text-lg py-3 hover:text-blue-400 transition-colors duration-300"
              >
                Contact
              </Menu.Item>
              {isLoggedIn ? (
                <Menu.Item key="logout" onClick={() => {
                  localStorage.removeItem('token');
                  localStorage.removeItem('username');
                  setIsLoggedIn(false);
                  setUsername("");
                  setIsMobileMenuOpen(false);
                }}>
                  Logout ({username})
                </Menu.Item>
              ) : (
                <>
                  <Menu.Item key="login" onClick={() => { setIsLoginModalOpen(true); setIsMobileMenuOpen(false); }}>
                    Login
                  </Menu.Item>
                  <Menu.Item key="register" onClick={() => { setIsRegisterModalOpen(true); setIsMobileMenuOpen(false); }}>
                    Register
                  </Menu.Item>
                </>
              )}
            </Menu>
          </div>
        )}
      </Header>

      <Content className="p-4 md:p-12">
        <Outlet />
      </Content>

      <Modal
        title={<Title level={4} className="text-center mb-0">Register</Title>}
        open={isRegisterModalOpen}
        onCancel={handleModalClose}
        footer={null}
      >
        <Fade>
          <Form 
            layout="vertical"
            onFinish={async (values) => {
              try {
                const response = await fetch('https://api.manuelplasc.com/auth/register', {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    username: values.email,
                    password: values.password,
                  }),
                });
                
                if (response.ok) {
                  message.success('Registration successful!');
                  handleModalClose();
                } else {
                  const data = await response.json();
                  message.error(data.message || 'Registration failed');
                }
              } catch (error) {
                message.error('Registration failed, try again later.');
              }
            }}
          >
            <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please enter your name" }]}>
              <Input placeholder="Enter your name" />
            </Form.Item>
            <Form.Item label="Lastname" name="lastname" rules={[{ required: true, message: "Please enter your lastname" }]}>
              <Input placeholder="Enter your lastname" />
            </Form.Item>
            <Form.Item label="Date of Birth" name="dob" rules={[{ required: true, message: "Please select your date of birth" }]}>
              <Input type="date" />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={[{ required: true, type: "email", message: "Please enter a valid email" }]}>
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item label="Password" name="password" rules={[{ required: true, message: "Please enter your password" }]}>
              <Input.Password placeholder="Enter your password" />
            </Form.Item>
            <Form.Item label="Confirm Password" name="confirmPassword" rules={[{ required: true, message: "Please confirm your password" }]}>
              <Input.Password placeholder="Confirm your password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>Register</Button>
            </Form.Item>
          </Form>
        </Fade>
      </Modal>

      <Modal
        title={<Title level={4} className="text-center mb-0">Login</Title>}
        open={isLoginModalOpen}
        onCancel={handleModalClose}
        footer={null}
      >
        <Fade>
          <Form 
            layout="vertical"
            onFinish={async (values) => {
              try {
                const response = await fetch('https://api.manuelplasc.com/auth/login', {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    username: values.email,
                    password: values.password,
                  }),
                });
                
                if (response.ok) {
                  const data = await response.json();
                  localStorage.setItem('token', data.token);
                  localStorage.setItem('username', values.email);
                  setIsLoggedIn(true);
                  setUsername(values.email);
                  message.success('Login successful!');
                  handleModalClose();
                } else {
                  const data = await response.json();
                  message.error(data.message || 'Login failed');
                }
              } catch (error) {
                message.error('Wrong username or password');
              }
            }}
          >
            <Form.Item label="Email" name="email" rules={[{ required: true, type: "email", message: "Please enter your email" }]}>
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item label="Password" name="password" rules={[{ required: true, message: "Please enter your password" }]}>
              <Input.Password placeholder="Enter your password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>Login</Button>
            </Form.Item>
            <Form.Item>
              <Button type="link" onClick={() => { setIsLoginModalOpen(false); setIsForgotModalOpen(true); }}>Forgot Password?</Button>
            </Form.Item>
          </Form>
        </Fade>
      </Modal>

      <Modal
        title={<Title level={4} className="text-center mb-0">Reset Password</Title>}
        open={isForgotModalOpen}
        onCancel={handleModalClose}
        footer={null}
      >
        <Fade>
          <Form layout="vertical">
            <Form.Item label="Email" name="email" rules={[{ required: true, type: "email", message: "Please enter your email" }]}>
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" block>Reset Password</Button>
            </Form.Item>
          </Form>
        </Fade>
      </Modal>
    </Layout>
  );
};

export default HomeScreen;
