import React from "react";
import { Form, Input, Button, Row, Col, Typography, Layout, Card } from "antd";
import { Fade } from "react-awesome-reveal";

const { TextArea } = Input;
const { Title } = Typography;
const { Content } = Layout;

const ContactScreen: React.FC = () => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Form Values:", values);
  };

  return (
    <Layout className="min-h-screen bg-[#f0f2f5]">
      <Content className="p-4 md:p-12">
        <Row gutter={[16, 32]} align="middle" justify="center">
          <Col xs={24} md={16} lg={12}>
            <Fade direction="left" triggerOnce>
              <Card className="p-4 md:p-8 rounded-lg bg-white shadow-lg">
                <div className="flex flex-col md:flex-row items-center gap-4 md:gap-6">
                  <img
                    src="/email.gif"
                    alt="Contact"
                    className="w-32 h-24 md:w-40 md:h-28 object-contain"
                  />
                  <Title level={2} className="text-center text-[#1c2a49] m-0">
                    Let's Talk
                  </Title>
                </div>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  className="mt-8"
                  requiredMark={false}
                >
                  <Fade direction="up" triggerOnce>
                    <Form.Item
                      name="name"
                      label={<span className="text-[#1c2a49] font-medium">Name</span>}
                      rules={[{ required: true, message: "Please enter your name" }]}
                    >
                      <Input 
                        placeholder="Enter your name" 
                        className="rounded-md h-10 hover:border-[#3b5998] focus:border-[#3b5998] focus:shadow-sm"
                      />
                    </Form.Item>
                  </Fade>
                  <Fade direction="up" delay={100} triggerOnce>
                    <Form.Item
                      name="email"
                      label={<span className="text-[#1c2a49] font-medium">Email</span>}
                      rules={[
                        { required: true, message: "Please enter your email" },
                        { type: "email", message: "Please enter a valid email" },
                      ]}
                    >
                      <Input 
                        placeholder="Enter your email" 
                        className="rounded-md h-10 hover:border-[#3b5998] focus:border-[#3b5998] focus:shadow-sm"
                      />
                    </Form.Item>
                  </Fade>
                  <Fade direction="up" delay={200} triggerOnce>
                    <Form.Item
                      name="subject"
                      label={<span className="text-[#1c2a49] font-medium">Subject</span>}
                      rules={[{ required: true, message: "Please enter a subject" }]}
                    >
                      <Input 
                        placeholder="Enter the subject" 
                        className="rounded-md h-10 hover:border-[#3b5998] focus:border-[#3b5998] focus:shadow-sm"
                      />
                    </Form.Item>
                  </Fade>
                  <Fade direction="up" delay={300} triggerOnce>
                    <Form.Item
                      name="message"
                      label={<span className="text-[#1c2a49] font-medium">Message</span>}
                      rules={[{ required: true, message: "Please enter a message" }]}
                    >
                      <TextArea 
                        rows={6} 
                        placeholder="Write your message here..." 
                        className="rounded-md hover:border-[#3b5998] focus:border-[#3b5998] focus:shadow-sm"
                      />
                    </Form.Item>
                  </Fade>
                  <Fade direction="up" delay={400} triggerOnce>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        className="bg-[#1c2a49] border-[#1c2a49] hover:bg-[#3b5998] hover:border-[#3b5998] h-12 text-lg rounded-md"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Fade>
                </Form>
              </Card>
            </Fade>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default ContactScreen;
