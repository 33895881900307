import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Avatar,
  Progress,
  Divider,
  Slider,
  Button,
} from "antd";
import { Fade } from "react-awesome-reveal";
import image1 from "../public/image 6.png";
import image2 from "../public/image 7.png";
import image3 from "../public/image 8.png";
import image4 from "../public/image 9.png";
import image5 from "../public/image 10.png";

const { Title, Text } = Typography;

interface Category {
  name: string;
  value: number;
  color: string;
  imageUrl: string;
}

const categories: Category[] = [
  { name: "Rent", value: 250, color: "#FFD700", imageUrl: image3 },
  { name: "Utilities", value: 210, color: "#FF4500", imageUrl: image2 },
  { name: "Groceries", value: 360, color: "#DC143C", imageUrl: image1 },
  { name: "Leisure", value: 130, color: "#00CED1", imageUrl: image4 },
  { name: "Savings", value: 70, color: "#9370DB", imageUrl: image5 },
];

interface BudgetValues {
  Rent: number;
  Utilities: number;
  Groceries: number;
  Leisure: number;
  Savings: number;
}

const BudgetingScreen: React.FC = () => {
  const [income, setIncome] = useState(Number(localStorage.getItem("income") ?? "0"));
  const [showIncomeInput, setShowIncomeInput] = useState(false);
  const [tempIncome, setTempIncome] = useState("");
  const [values, setValues] = useState<BudgetValues>({
    Rent: 20,
    Utilities: 15,
    Groceries: 25,
    Leisure: 10,
    Savings: 30,
  });

  const handleIncomeSubmit = () => {
    const newIncome = Number(tempIncome);
    if (!isNaN(newIncome) && newIncome >= 0) {
      setIncome(newIncome);
      localStorage.setItem("income", newIncome.toString());
      setShowIncomeInput(false);
      setTempIncome("");
    }
  };

  const handleChange = (category: keyof BudgetValues, newValue: number) => {
    setValues((prevValues) => {
      const oldValue = prevValues[category];
      const diff = newValue - oldValue;
      
      // If trying to decrease below 0 or increase above 100, don't change
      if (newValue < 0 || newValue > 100) return prevValues;

      const otherCategories = Object.keys(prevValues).filter(
        (cat) => cat !== category
      ) as Array<keyof BudgetValues>;

      // Calculate total of other categories
      const otherTotal = otherCategories.reduce(
        (sum, cat) => sum + prevValues[cat],
        0
      );

      // If other categories total is 0, don't allow further decrease
      if (otherTotal === 0 && diff > 0) return prevValues;

      const newValues = { ...prevValues };
      newValues[category] = newValue;

      // Adjust other categories proportionally
      if (diff !== 0) {
        const factor = (otherTotal - diff) / otherTotal;
        otherCategories.forEach((cat) => {
          newValues[cat] = Math.max(0, Math.round(prevValues[cat] * factor));
        });

        // Ensure total is exactly 100
        const total = Object.values(newValues).reduce((sum, val) => sum + val, 0);
        if (total !== 100) {
          const lastCategory = otherCategories[otherCategories.length - 1];
          newValues[lastCategory] += 100 - total;
        }
      }

      return newValues;
    });
  };

  return (
    <div className="p-4 md:p-12 bg-[#f9f9f9] min-h-screen">
      <Row gutter={[16, 32]}>
          <Col xs={24} md={12}>
          <Fade direction="left" triggerOnce>
              <Card className="rounded-lg text-center p-4 md:p-6 shadow-md transform transition-all duration-300">
              <Title level={3} className="text-transparent bg-clip-text bg-gradient-to-r from-[#3b5998] to-[#4267B2] font-bold">
                My Income
              </Title>
              <div className="mt-8 space-y-6">
                <div className="relative inline-block">
                  <Avatar
                    size={120}
                    className="bg-gradient-to-r from-[#3b5998] to-[#4267B2] text-white shadow-md transform transition-transform duration-300 hover:scale-105"
                    style={{ fontSize: '2.5rem' }}
                  >
                    €
                  </Avatar>
                </div>
                {showIncomeInput ? (
                  <div className="mt-6 space-y-4">
                    <input
                      type="number"
                      value={tempIncome}
                      onChange={(e) => setTempIncome(e.target.value)}
                      className="w-48 p-3 border-2 border-[#3b5998]/20 rounded-lg text-center text-lg focus:outline-none focus:border-[#3b5998] transition-colors duration-300"
                      placeholder="Enter income"
                      min="0"
                    />
                    <div className="flex justify-center gap-3">
                      <Button
                        type="primary"
                        onClick={handleIncomeSubmit}
                        className="bg-gradient-to-r from-[#3b5998] to-[#4267B2] border-none shadow-md hover:opacity-90"
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => setShowIncomeInput(false)}
                        className="border-[#3b5998]/30 text-[#3b5998] hover:border-[#3b5998] hover:text-[#4267B2] transition-colors duration-300"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="space-y-4">
                    <Text className="block text-3xl font-semibold bg-gradient-to-r from-[#3b5998] to-[#4267B2] text-transparent bg-clip-text animate-fade-in">
                      {income}€
                    </Text>
                    <Button 
                      type="primary" 
                      size="large" 
                      onClick={() => setShowIncomeInput(true)} 
                      className="bg-gradient-to-r from-[#3b5998] to-[#4267B2] border-none shadow-md hover:opacity-90 px-8"
                    >
                      Change
                    </Button>
                  </div>
                )}
              </div>
            </Card>
          </Fade>
        </Col>

        <Col xs={24} md={12}>
          <Fade direction="left" triggerOnce>
            <Card className="rounded-lg p-4 md:p-6 shadow-md">
              <Title level={2} className="text-center mb-6 text-[#3B82F6]">
                Budget Categories
              </Title>
              {Object.keys(values).map((category) => (
                <div key={category} style={{ marginBottom: "20px" }}>
                  <Title level={5}>{category}</Title>
                  <Slider
                    min={0}
                    max={100}
                    value={values[category as keyof BudgetValues]}
                    onChange={(value) =>
                      handleChange(category as keyof BudgetValues, value)
                    }
                    tooltip={{ formatter: (val) => `${val}%` }}
                  />
                </div>
              ))}
            </Card>
          </Fade>
          </Col>
      </Row>

      <Divider className="my-8 md:my-12">Categories</Divider>

      <Fade direction="down" triggerOnce>
        <Row gutter={[16, 16]}>
          {categories.map((category) => (
            <Col xs={24} sm={12} md={8} lg={6} key={category.name}>
              <Card
                hoverable
                className="rounded-lg shadow-md transform transition-transform duration-300 hover:scale-105"
              >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                      <div
                        className="w-16 h-16 rounded-full flex items-center justify-center"
                        style={{ backgroundColor: category.color }}
                      >
                        <img
                          src={category.imageUrl}
                          alt={category.name}
                          className="w-3/5 h-3/5 object-contain"
                        />
                      </div>
                      <Title level={4} className="m-0">
                        {category.name}
                      </Title>
                    </div>
                    <Progress
                      type="circle"
                      percent={values[category.name as keyof BudgetValues]}
                      format={() => `${Math.round(income * values[category.name as keyof BudgetValues] / 100)}€`}
                      strokeColor={category.color}
                      size={80}
                    />
                  </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Fade>
    </div>
  );
};

export default BudgetingScreen;
