import React, { useState } from "react";
import { Layout, Card, Typography, Button, Divider, Input, Alert } from "antd";
import { Fade } from "react-awesome-reveal";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const TaxTutorialPage: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [quizVisible, setQuizVisible] = useState(false);
  const [quizAnswer, setQuizAnswer] = useState("");
  const [quizFeedback, setQuizFeedback] = useState<"correct" | "incorrect" | "">("");

  const slides = [
    {
      title: "Taxes in Croatia",
      content: "Learn about the tax system in Croatia, including Income Tax, VAT, Corporate Tax, and more.",
    },
    {
      title: "Income Tax (Porez na Dohodak)",
      content: (
        <>
          <Paragraph>
            Income Tax in Croatia is progressive. The tax rates are:
          </Paragraph>
          <ul>
            <li>20% for income up to HRK 360,000 annually.</li>
            <li>30% for income exceeding HRK 360,000 annually.</li>
          </ul>
        </>
      ),
    },
    {
      title: "VAT (PDV)",
      content: (
        <>
          <Paragraph>
            Value Added Tax in Croatia is charged at three rates:
          </Paragraph>
          <ul>
            <li>25%: Standard rate for most goods and services.</li>
            <li>13%: Reduced rate for hospitality, food oils, and more.</li>
            <li>5%: Reduced rate for essential items like books and medicines.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Corporate Tax",
      content: (
        <>
          <Paragraph>
            Corporate tax rates in Croatia depend on the company's revenue:
          </Paragraph>
          <ul>
            <li>10%: Revenue up to HRK 7.5 million.</li>
            <li>18%: Revenue exceeding HRK 7.5 million.</li>
          </ul>
        </>
      ),
    },
  ];

  const handleNextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setQuizVisible(true);
    }
  };

  const handlePreviousSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const handleQuizSubmit = () => {
    if (quizAnswer.toLowerCase() === "20%") {
      setQuizFeedback("correct");
    } else {
      setQuizFeedback("incorrect");
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#f9f9f9", padding: "20px" }}>
      <Content style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <Fade>
          <Card
            style={{
              marginBottom: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Title level={2} style={{ textAlign: "center", color: "#3b5998" }}>
              {slides[currentSlide].title}
            </Title>
            <Paragraph>{slides[currentSlide].content}</Paragraph>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
              <Button
                type="default"
                onClick={handlePreviousSlide}
                disabled={currentSlide === 0}
              >
                Previous
              </Button>
              <Button
                type="primary"
                onClick={handleNextSlide}
                disabled={currentSlide === slides.length - 1 && quizVisible}
              >
                Next
              </Button>
            </div>
          </Card>
        </Fade>

        {quizVisible && (
          <>
            <Divider>Interactive Exercise</Divider>
            <Fade>
              <Card style={{ marginBottom: "20px", textAlign: "center" }}>
                <Title level={4}>What is the tax rate for income up to HRK 360,000?</Title>
                <Input
                  placeholder="Enter your answer (e.g., 20%)"
                  value={quizAnswer}
                  onChange={(e) => setQuizAnswer(e.target.value)}
                  style={{ width: "300px", marginBottom: "20px" }}
                />
                <Button type="primary" onClick={handleQuizSubmit}>
                  Submit
                </Button>
                {quizFeedback === "correct" && (
                  <Alert
                    message="Correct! The tax rate is 20%."
                    type="success"
                    style={{ marginTop: "20px" }}
                  />
                )}
                {quizFeedback === "incorrect" && (
                  <Alert
                    message="Incorrect. Please try again."
                    type="error"
                    style={{ marginTop: "20px" }}
                  />
                )}
              </Card>
            </Fade>
          </>
        )}

        <Divider>Resources</Divider>
        <Fade>
          <Card>
            <Paragraph>
              For more information on taxes in Croatia, visit the official Croatian Tax Administration website:
              <a
                href="https://www.porezna-uprava.hr"
                target="_blank"
                rel="noopener noreferrer"
              > Porezna Uprava
              </a>
            </Paragraph>
          </Card>
        </Fade>
      </Content>
    </Layout>
  );
};

export default TaxTutorialPage;
