import React from "react";
import { Button, Card, Carousel, Typography, Layout, Avatar } from "antd";
import { Fade, Slide } from "react-awesome-reveal";
import {
  ArrowRightOutlined,
  DollarCircleOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import knowledgeAnimation from "../public/basicAnim.json";
import budgetingAnimation from "../public/budgetingAnim.json";
import personalAnimation from "../public/personalAnim.json";
import Player from "lottie-react";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const HomeScreen = () => {
  const handleLearnMoreClick = () => {
    window.location.href = "/contact";
  };

  const handleBudgetingClick = () => {
    window.location.href = "/budgeting";
  };

  const handleSimulationsClick = () => {
    window.location.href = "/dashboard";
  };

  const cardsData = [
    {
      icon: (
        <DollarCircleOutlined style={{ fontSize: "48px", color: "#3b5998" }} />
      ),
      title: "DISCOVER",
      description:
        "Discover tools to build financial confidence, manage money wisely, and plan for a brighter future.",
    },
    {
      icon: <RocketOutlined style={{ fontSize: "48px", color: "#3b5998" }} />,
      title: "LEARN",
      description:
        "Learn how to budget, save, invest, and secure your financial future for peace of mind.",
    },
    {
      icon: (
        <ArrowRightOutlined style={{ fontSize: "48px", color: "#3b5998" }} />
      ),
      title: "GROW",
      description:
        "Grow your financial confidence, build wealth, and achieve goals through smart planning.",
    },
  ];

  const testimonialsData = [
    {
      name: "Ivica Kratić",
      description:
        "Budget Buddies has been a tremendous tool in keeping up with my monthly expenses and budgeting for the future!",
      imgSrc: "/maleOne.jpg",
    },
    {
      name: "Ana Marić",
      description:
        "If it weren't for Budget Buddies, I never would have saved up for that family trip to Italy. Highly recommended!",
      imgSrc: "femaleOne.jpg",
    },
    {
      name: "Sandra Janković",
      description:
        "I didn't know the first thing about how to save money and budget properly. And then I found Budget Buddies.",
      imgSrc: "femaleTwo.jpg",
    },
  ];

  const actionCards = [
    {
      title: "Simulations",
      description: "Engage in real-life financial decision-making scenarios.",
      lottieAnimation: knowledgeAnimation,
      onClick: handleSimulationsClick,
    },
    {
      title: "Budgeting",
      description: "Master the art of budgeting with interactive tools.",
      lottieAnimation: budgetingAnimation,
      onClick: handleBudgetingClick,
    },
  ];

  return (
    <Layout className="min-h-screen bg-[#f0f2f5]">
      {/* Header Section */}
      <section className="min-h-[80vh] md:h-[85vh] flex flex-col md:flex-row items-center justify-between bg-cover bg-center rounded-t-lg relative overflow-hidden">
        {/* Background Layers */}
        <div className="absolute inset-0 bg-gradient-to-r from-[#1c2a49] via-[#1c2a49dd] to-transparent"></div>
        <div className="absolute inset-0" style={{ backgroundImage: "url(/bbCover.jpg)", backgroundSize: "cover", backgroundPosition: "center", zIndex: -1 }}></div>
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCI+CiAgPHBhdGggZD0iTTAgMGg2MHY2MEgweiIgZmlsbD0ibm9uZSIvPgogIDxwYXRoIGQgPSJNMzAgMTBjLTExLjA1IDAtMjAgOC45NS0yMCAyMHM4Ljk1IDIwIDIwIDIwIDIwLTguOTUgMjAtMjBTNDEuMDUgMTAgMzAgMTB6bTAgMzZjLTguODQgMC0xNi03LjE2LTE2LTE2czYuMTYtMTYgMTYtMTYgMTYgNy4xNiAxNiAxNi03LjE2IDE2LTE2IDE2eiIgZmlsbD0icmdiYSgyNTUsMjU1LDI1NSwwLjAzKSIvPgo8L3N2Zz4=')] opacity-30"></div>
        
        {/* Content */}
        <Fade direction="down" cascade triggerOnce className="w-full md:w-1/2">
          <div className="relative z-10 px-6 md:px-16 py-12 md:py-0">
            <div className="inline-block mb-4 px-4 py-1 bg-blue-500 bg-opacity-20 rounded-full">
              <span className="text-blue-300 font-semibold">Your Financial Journey Starts Here</span>
            </div>
            <Title style={{ color: "#fcfcfc" }} className="text-white text-4xl md:text-5xl lg:text-6xl font-bold leading-tight mb-6">
              Master Your Money,<br/>
              <span className="text-blue-400">Shape Your Future</span>
            </Title>
            <Paragraph className="text-gray-200 text-lg md:text-xl mb-8 max-w-xl">
              Join thousands of learners mastering their finances through interactive education, smart budgeting tools, and personalized guidance.
            </Paragraph>
            <div className="flex flex-wrap gap-4">
              <Button 
                type="primary" 
                size="large" 
                onClick={handleLearnMoreClick}
                className="min-w-[160px] h-12 text-lg font-semibold hover:scale-105 transition-transform"
              >
                Get Started
              </Button>
              <Button 
                size="large" 
                onClick={handleBudgetingClick}
                className="min-w-[160px] h-12 text-lg font-semibold border-2 text-white hover:text-blue-400 hover:border-blue-400 transition-colors"
                ghost
              >
                Try Budgeting
              </Button>
            </div>
            
            {/* Quick Stats */}
            <div className="mt-12 grid grid-cols-2 md:grid-cols-3 gap-8">
              <div className="text-center">
                <div className="text-3xl font-bold text-blue-400">10K+</div>
                <div className="text-gray-300 text-sm">Active Users</div>
              </div>
              <div className="text-center">
                <div className="text-3xl font-bold text-blue-400">95%</div>
                <div className="text-gray-300 text-sm">Success Rate</div>
              </div>
              <div className="hidden md:block text-center">
                <div className="text-3xl font-bold text-blue-400">24/7</div>
                <div className="text-gray-300 text-sm">Support</div>
              </div>
            </div>
          </div>
        </Fade>
        
        {/* Right Side Animation */}
        <div className="relative z-10 w-full md:w-1/2 h-full flex items-center justify-center p-8">
          <Player
            autoplay
            loop
            animationData={personalAnimation}
            style={{
              width: "100%",
              maxWidth: "600px"
            }}
          />
        </div>
      </section>

      {/* Carousel Section */}
      <section className="min-h-[50vh] bg-white py-8 md:py-12">
        <Content className="px-4 md:px-12">
          <Carousel autoplay className="pb-8">
            {/* Features Cards */}
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
                {cardsData.map((card, index) => (
                  <Card
                    key={index}
                    hoverable
                    className="text-center p-6"
                    cover={card.icon}
                  >
                    <Title level={4}>{card.title}</Title>
                    <Paragraph>{card.description}</Paragraph>
                  </Card>
                ))}
              </div>
            </div>

            {/* Testimonials Slide */}
            <div className="px-4">
              <Title level={2} className="text-center text-[#3b5998] mb-8">
                Testimonials
              </Title>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
                {testimonialsData.map((testimonial, index) => (
                  <Card
                    key={index}
                    hoverable
                    className="text-center"
                    cover={
                      <Avatar
                        src={testimonial.imgSrc}
                        size={100}
                        className="mx-auto mt-4"
                      />
                    }
                  >
                    <Card.Meta
                      title={testimonial.name}
                      description={testimonial.description}
                    />
                  </Card>
                ))}
              </div>
            </div>
          </Carousel>
        </Content>
      </section>

      {/* Action Cards Section */}
      <section className="min-h-[50vh] bg-[#1c2a49] rounded-b-lg py-12">
        <Slide direction="up" triggerOnce>
          <div className="flex flex-col md:flex-row justify-center items-center gap-8 md:gap-12 px-4 md:px-12">
            {actionCards.map((action, index) => (
              <div
                key={index}
                onClick={action.onClick}
                className="cursor-pointer relative w-64 h-64 md:w-72 md:h-72 rounded-full overflow-hidden bg-white shadow-lg text-center transform hover:scale-105 transition-transform duration-300"
                style={{
                  animation: `floatAnimation ${index % 2 === 0 ? "3s" : "4s"} infinite alternate ease-in-out`,
                }}
              >
                <Player
                  autoplay
                  loop
                  animationData={action.lottieAnimation}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    pointerEvents: "none",
                  }}
                />

                {/* Card Title */}
                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 w-4/5 bg-[#1c2a49] text-white py-2 px-4 rounded text-sm shadow-md z-20">
                  {action.title}
                </div>
              </div>
            ))}
          </div>
        </Slide>

        {/* Keyframe Animation */}
        <style>
          {`
      @keyframes floatAnimation {
        from {
          transform: translateY(0);
        }
        to {
          transform: translateY(-10px);
        }
      }
    `}
        </style>
      </section>
    </Layout>
  );
};

export default HomeScreen;
