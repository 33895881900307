import React from "react";
import { Typography, Row, Col, Layout, Card, Button } from "antd";
import { Fade } from "react-awesome-reveal";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const AboutUsScreen: React.FC = () => {
  const handleLearnMoreClick = () => {
    window.location.href = "/contact";
  };

  return (
    <Layout className="min-h-screen">
      <Content className="p-4 md:p-12">
        <Row gutter={[16, 32]} align="middle" justify="center">
          <Col xs={24} md={12}>
            <Fade direction="left" triggerOnce>
              <img
                src="/piggyBank.png"
                alt="About Us"
                className="w-full h-full min-h-[400px] max-w-[600px] mx-auto rounded-lg shadow-md object-cover"
              />
            </Fade>
          </Col>
          <Col xs={24} md={12}>
            <Fade direction="right" triggerOnce>
              <Card className="p-4 md:p-6 rounded-lg shadow-md">
                <Title level={2} className="text-center text-[#3b5998] mb-6">
                  About Us
                </Title>
                <Paragraph className="text-base md:text-lg leading-relaxed text-justify mb-4">
                  Budget Buddies was founded by a group of passionate young students who wanted to make financial education accessible, fun, and relatable for everyone. We understand that managing money can be overwhelming, especially when you're just starting out, so we created Budget Buddies to help simplify the process.
                </Paragraph>
                <Paragraph className="text-base md:text-lg leading-relaxed text-justify mb-4">
                  Our goal is to provide tools and resources that empower you to take control of your finances, no matter where you are in your journey. Through our interactive financial simulations, we allow you to experience real-life events in a game-like format, giving you the chance to make decisions, see the outcomes, and learn from your choices in a risk-free environment.
                </Paragraph>
                <Paragraph className="text-base md:text-lg leading-relaxed text-justify">
                  At Budget Buddies, we believe that learning about money should be engaging and stress-free. Join us today, and start building a solid foundation for your financial future in a way that's simple, fun, and completely in your control.
                </Paragraph>
              </Card>
            </Fade>
          </Col>
        </Row>

        <div className="relative mt-12 md:mt-16">
          <img
            src="/aboutUs.png"
            alt="Ready"
            className="w-full rounded-lg h-[40vh] md:h-[60vh] object-cover"
          />
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 rounded-lg">
            <Fade direction="up" triggerOnce>
              <Title level={2} style={{ color: "white" }} className="text-white text-center text-2xl md:text-4xl lg:text-5xl drop-shadow-lg mb-6">
                So, are you ready to learn with us?
              </Title>
              <div className="mt-4 md:mt-6">
                <Button type="primary" size="large" className="bg-[#3b5998] border-[#3b5998] hover:bg-[#2d4373] hover:border-[#2d4373]" onClick={handleLearnMoreClick}>
                  Get Started
                </Button>
              </div>
            </Fade>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default AboutUsScreen;
